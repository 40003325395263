

















































































































































































































































































































































































































































import dateFormat from 'dateformat'
import { Api, EngagementReportSchoolInfo, EngagementReportSchoolStub, EngagementReportSnapshotInfo, ISO8601Date } from '@/edshed-common/api'
import { SubscriptionPlanAccountRegion, SubscriptionPlanAccountType, SubscriptionPlanGroup, SubscriptionPlanCostType } from '@/edshed-common/subscriptionPackages'
import moment from 'moment'
import { Component, Vue } from 'vue-property-decorator'
import { SubscriptionProductType } from '@/edshed-common/api/types/subscriptions'

@Component({
  name: 'EngagementsView'
})
export default class EngagmentsView extends Vue {
  private tablePage = 1
  private tablePerPage = 10
  private tableData: EngagementReportSnapshotInfo[] = []
  private tableTotal = 0
  private isTableLoading = false

  private previewTablePage = 1
  private previewTablePerPage = 10
  private previewTableData: EngagementReportSchoolStub[] = []
  private previewTableTotal = 0
  private isPreviewTableLoading = false

  private reportTablePage = 1
  private reportTablePerPage = 10
  private reportTableData: EngagementReportSchoolInfo[] = []
  private reportTableTotal = 0
  private isReportTableLoading = false
  private reportTableShowTotals = false
  private reportTableSortCol = 'id'
  private reportTableSortDir: 'asc' | 'desc' = 'asc'

  private showCreateSnapshotModal = false
  private showPreviewSchoolsModal = false
  private showReportModal = false

  private filterGroups: SubscriptionPlanGroup[] = []
  private filterAccountTypes: SubscriptionPlanAccountType[] = []
  private filterCostTypes: SubscriptionPlanCostType[] = []
  private filterEdshedProducts: SubscriptionProductType[] = []
  private filterAccountRegions: SubscriptionPlanAccountRegion[] = []

  private snapshotStartDate: Date = new Date()
  private snapshotEndDate: Date = new Date()

  private selectedSnapshotId: number | null = null

  SubscriptionPlanGroup = SubscriptionPlanGroup
  SubscriptionPlanAccountType = SubscriptionPlanAccountType
  SubscriptionPlanCostType = SubscriptionPlanCostType
  SubscriptionPlanProduct = SubscriptionProductType
  SubscriptionPlanAccountRegion = SubscriptionPlanAccountRegion

  dateFormat = dateFormat

  async mounted () {
    await this.getSnapshots()
    this.snapshotStartDate = moment().subtract(1, 'y').toDate()
  }

  async getSnapshots () {
    try {
      this.isTableLoading = true

      const snapshots = await Api.getEngagementSnapshots({ take: this.tablePerPage, skip: (this.tablePage - 1) * this.tablePerPage })
      this.tableData = snapshots.items
      this.tableTotal = snapshots.total
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load snapshot data',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.isTableLoading = false
    }
  }

  async getSchoolsPreview () {
    this.showPreviewSchoolsModal = true
    try {
      this.isPreviewTableLoading = true

      this.previewTableData = []
      this.previewTableTotal = 0

      const schools = await Api.previewEngagementSnapshotSchools(this.snapshotStartDate.toISOString() as ISO8601Date, this.snapshotEndDate.toISOString() as ISO8601Date, {
        groups: this.filterGroups,
        products: this.filterEdshedProducts,
        regions: this.filterAccountRegions,
        account_types: this.filterAccountTypes,
        cost_types: this.filterCostTypes
      }, {
        skip: (this.previewTablePage - 1) * this.previewTablePerPage,
        take: this.previewTablePerPage
      })

      this.previewTableData = schools.items
      this.previewTableTotal = schools.total
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load preview data',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.isPreviewTableLoading = false
    }
  }

  async getReportData () {
    if (!this.selectedSnapshotId) {
      return
    }

    this.showReportModal = true
    try {
      this.isReportTableLoading = true

      const schools = await Api.filterEngagementSnapshot(this.selectedSnapshotId, {
        skip: (this.reportTablePage - 1) * this.reportTablePerPage,
        take: this.reportTablePerPage,
        sort: this.reportTableSortCol,
        dir: this.reportTableSortDir
      })

      this.reportTableData = schools.items
      this.reportTableTotal = schools.total
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load report data',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.isReportTableLoading = false
    }
  }

  async downloadReportData () {
    if (!this.selectedSnapshotId) {
      return
    }

    this.showReportModal = true
    try {
      this.isReportTableLoading = true

      const data = await Api.downloadEngagementSnapshot(this.selectedSnapshotId, {
        sort: this.reportTableSortCol,
        dir: this.reportTableSortDir
      })

      console.log(data)

      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data)
      anchor.target = '_blank'
      anchor.download = 'engagement_report.csv'
      anchor.click()
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load report data',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.isReportTableLoading = false
    }
  }

  async createSnapshot () {
    try {
      await Api.generateEngagementSnapshot(this.snapshotStartDate.toISOString() as ISO8601Date, this.snapshotEndDate.toISOString() as ISO8601Date, {
        groups: this.filterGroups,
        products: this.filterEdshedProducts,
        regions: this.filterAccountRegions,
        account_types: this.filterAccountTypes,
        cost_types: this.filterCostTypes
      })

      this.showPreviewSchoolsModal = false
      this.showCreateSnapshotModal = false

      await this.getSnapshots()
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load preview data',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.isPreviewTableLoading = false
    }
  }

  async pageChanged (page: number) {
    this.tablePage = page
    await this.getSnapshots()
  }

  async previewPageChanged (page: number) {
    this.previewTablePage = page
    await this.getSchoolsPreview()
  }

  async reportPageChanged (page: number) {
    this.reportTablePage = page
    await this.getReportData()
  }

  async reportSortChanged (col: string, dir: 'asc' | 'desc') {
    this.reportTableSortCol = col
    this.reportTableSortDir = dir

    await this.getReportData()
  }

  async viewSnapshotSelected (id: number) {
    this.selectedSnapshotId = id
    this.showReportModal = true
    await this.getReportData()
  }

  confirmDeleteSnapshot (id: number) {
    this.$buefy.dialog.confirm({
      title: 'Deleting snapshot',
      message: `Are you sure you want to <b>delete</b> snapshot id #${id}? This action cannot be undone.`,
      confirmText: 'Delete Snapshot',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteSnapshot(id)
    })
  }

  async deleteSnapshot (id: number) {
    try {
      await Api.deleteEngagementSnapshot(id)

      this.$buefy.toast.open({
        message: 'Snapshot deleted',
        type: 'is-success'
      })
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not delete snapshot',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.getSnapshots()
    }
  }

  async totalTypeChanged (value: boolean) {
    const map = [
      ['admin_events_per_admin', 'admin_events'],
      ['log_ins_per_user', 'log_ins'],
      ['spelling_games_per_pupil', 'spelling_games_played'],
      ['number_games_per_pupil', 'number_games_played'],
      ['quiz_games_per_pupil', 'quiz_games_played'],
      ['phonics_games_per_pupil', 'phonics_games_played']
    ]

    const idx = value ? 0 : 1
    const switchValue = map.find(r => r[idx] === this.reportTableSortCol)

    if (switchValue) {
      switchValue.splice(idx, 1)
      this.reportTableSortCol = switchValue[0]
      await this.getReportData()
    }
  }
}
